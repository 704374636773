import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { useChromeExtension } from './extension'
const CampaignsPage = () => import('./campaign/CampaignsPage.vue')
const AudiencesPage = () => import('./audience/AudiencesPage.vue')
const StandaloneAudiencesPage = () => import('./audience/StandaloneAudiencesPage.vue')
const InviteAcceptPage = () => import('./auth/InviteAcceptPage.vue')
const LoginPage = () => import('./auth/LoginPage.vue')
const OnboardingPage = () => import('./auth/OnboardingPage.vue')
const ManageAccountPage = () => import('./auth/ManageAccountPage.vue')
const CreativesPage = () => import('./creative/CreativesPage.vue')
const MediaOverview = () => import('./media/MediaOverview.vue')
const ChooseTeamPage = () => import('./team/ChooseTeamPage.vue')
const HelperDocumentsPage = () => import('./team/HelperDocumentsPage.vue')
const TeamSettingsPage = () => import('./team/TeamSettingsPage.vue')
const AudienceCreator = () => import('./audience-creator/AudienceCreator.vue')
const ExploreX = () => import('./audience-creator/ExploreX.vue')
const TeamRootPage = () => import('./team/TeamRootPage.vue')
const CommunitiesPage = () => import('./communities/CommunitiesPage.vue')
const ResetE2EPage = () => import('./e2e/ResetE2EPage.vue')
const DeepThoughtNotFoundPage = () => import('./deep-thought/pages/404.vue')
const DeepThoughtLoginPremiumPage = () => import('./deep-thought/pages/LoginPremiumPage.vue')
const DeepThoughtHomePage = () => import('./deep-thought/pages/HomePage.vue')
const DeepThoughtDashboardPage = () => import('./deep-thought/pages/DashboardPage.vue')
const DeepThoughtTalkToXPage = () => import('./deep-thought/pages/TalkToX.vue')
const DeepThoughtFollowersPage = () => import('./deep-thought/components/DashboardAccountInfoFollowers.vue')
const DeepThoughtFollowingPage = () => import('./deep-thought/components/DashboardAccountInfoFollowing.vue')
const DeepThoughtPostPage = () => import('./deep-thought/components/TwitterPostItem.vue')
const ProTargetingHomePage = () => import('./pro-targeting/pages/HomePage.vue')
const ProTargetingIndustriesPage = () => import('./pro-targeting/pages/IndustriesPage.vue')
const ProTargetingIndustryPage = () => import('./pro-targeting/pages/IndustryPage.vue')
const ProTargetingSavedListsPage = () => import('./pro-targeting/pages/SavedListsPage.vue')
const ProTargetingCampaignsNewPage = () => import('./pro-targeting/pages/CampaignsNewPage.vue')
const ProTargetingTargetingListsPage = () => import('./pro-targeting/pages/TargetingListsPage.vue')
const ProTargetingMarketingMaterialsPage = () => import('./pro-targeting/pages/MarketingMaterialsPage.vue')
const ProTargetingExclusionAudiencesPage = () => import('./pro-targeting/pages/ExclusionAudiencesPage.vue')
const ProTargetingAccountBasedMarketingPage = () => import('./pro-targeting/pages/AccountBasedMarketingPage.vue')
const PartnershipPage = () => import('./pro-targeting/pages/PartnershipPage.vue')
const ProTargetingPage = () => import('./pro-targeting/pages/ProTargetingPage.vue')
const ProTargetingIntegrationPage = () => import('./pro-targeting/pages/ProTargetingIntegrationPage.vue')
const ProTargetingRequestAudiencePage = () => import('./pro-targeting/components/RequestAudienceContactForm.vue')
const ProTargetingRequestAccessPage = () => import('./pro-targeting/components/RequestAccess.vue')
const ProTargetingRequestDownloadPage = () => import('./pro-targeting/components/RequestDownload.vue')
const ProTargetingRequestConfirmationPage = () => import('./pro-targeting/components/RequestConfirmation.vue')
const ProTargetingRequestVerificationPage = () => import('./pro-targeting/components/RequestVerification.vue')

const { isInExtension } = useChromeExtension()

const dashboardRoutes: RouteRecordRaw[] = [
  { path: '/login/:network?', name: 'login', component: LoginPage, props: true, meta: { layout: 'dashboard-no-nav' } },
  {
    path: '/:teamId/invite-accept',
    name: 'invite-accept',
    component: InviteAcceptPage,
    meta: { layout: 'dashboard-no-nav' },
  },
  {
    path: '/welcome',
    name: 'onboarding',
    component: OnboardingPage,
    props: true,
    meta: { layout: 'dashboard-no-nav' },
  },
  { path: '/choose-team', name: 'choose-team', component: ChooseTeamPage, meta: { layout: 'dashboard-no-nav' } },
  {
    path: '/helper-documents',
    name: 'helper-documents',
    component: HelperDocumentsPage,
    meta: { layout: 'dashboard-no-nav' },
  },

  {
    path: '/audiences',
    name: 'standalone-audiences',
    component: StandaloneAudiencesPage,
    meta: { layout: 'dashboard-no-nav', header: true },
  },

  {
    path: '/:teamId/',
    name: 'team-root',
    component: TeamRootPage,
    props: true,
    meta: { layout: 'dashboard-no-nav' },
    redirect: { name: 'audiences' },
    children: [
      { path: 'account', name: 'manage-account', component: ManageAccountPage, meta: { layout: 'dashboard' } },
      { path: 'team', name: 'team-settings', component: TeamSettingsPage, meta: { layout: 'dashboard' } },
      { path: 'campaigns', name: 'campaigns', component: CampaignsPage, meta: { layout: 'dashboard', header: true } },
      { path: 'creatives', name: 'creatives', component: CreativesPage, meta: { layout: 'dashboard' } },
      { path: 'media', name: 'media', component: MediaOverview, meta: { layout: 'dashboard' } },
      {
        path: 'creator',
        name: 'audience-creator',
        component: AudienceCreator,
        meta: { layout: 'dashboard', header: true },
      },
      { path: 'explore-x', name: 'explore-x', component: ExploreX, meta: { layout: 'dashboard', header: true } },
      { path: 'audiences', name: 'audiences', component: AudiencesPage, meta: { layout: 'dashboard', header: true } },

      // These pages are no longer in the nav menu, but are still accessible via direct link:
      {
        path: 'communities',
        name: 'communities',
        component: CommunitiesPage,
        meta: { layout: 'dashboard', header: true },
      },
    ],
  },

  { path: '/reset-e2e', name: 'reset-e2e', component: ResetE2EPage, meta: { layout: 'none' } },

  { path: '/:pathMatch(.*)*', redirect: 'choose-team' },
]

const proTargetingRoutes: RouteRecordRaw[] = [
  { path: '/', name: 'homepage', component: ProTargetingHomePage, meta: { layout: 'pro-targeting' } },
  {
    path: '/saved-lists',
    name: 'saved-lists',
    component: ProTargetingSavedListsPage,
    meta: { layout: 'pro-targeting', backgroundColor: 'white' },
  },
  {
    path: '/campaigns/new',
    name: 'campaigns-new',
    component: ProTargetingCampaignsNewPage,
    meta: { layout: 'pro-targeting', backgroundColor: 'white' },
  },
  {
    path: '/targeting-lists/:id?',
    name: 'targeting-lists',
    alias: ['/native-lists/:id?', '/native-list/:id?'],
    component: ProTargetingTargetingListsPage,
    meta: { layout: 'pro-targeting', backgroundColor: 'white' },
  },
  {
    path: '/industries/:event',
    name: 'industry',
    alias: '/events/:event',
    component: ProTargetingIndustryPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/industries',
    name: 'industries',
    alias: '/events',
    component: ProTargetingIndustriesPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/marketing-materials',
    name: 'marketing-materials',
    alias: '/materials',
    component: ProTargetingMarketingMaterialsPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/exclusion-audiences',
    name: 'exclusion-audiences',
    component: ProTargetingExclusionAudiencesPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/account-based-marketing',
    name: 'account-based-marketing',
    alias: '/abm',
    component: ProTargetingAccountBasedMarketingPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/about-our-partnership',
    name: 'about-our-partnership',
    alias: '/partnership',
    component: PartnershipPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/about-pro-targeting',
    name: 'about-pro-targeting',
    alias: '/pro-targeting',
    component: ProTargetingPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/pro-targeting-integration',
    name: 'pro-targeting-integration',
    component: ProTargetingIntegrationPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/request-audience',
    name: 'request-audience',
    alias: '/request-audiences',
    component: ProTargetingRequestAudiencePage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/request-download/:title?',
    name: 'request-download',
    component: ProTargetingRequestDownloadPage,
    meta: { layout: 'pro-targeting' },
    props: true,
  },
  {
    path: '/request-access',
    name: 'request-access',
    component: ProTargetingRequestAccessPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/request-confirmation',
    name: 'request-confirmation',
    component: ProTargetingRequestConfirmationPage,
    meta: { layout: 'pro-targeting' },
  },
  {
    path: '/request-verification',
    name: 'request-verification',
    component: ProTargetingRequestVerificationPage,
    meta: { layout: 'pro-targeting' },
  },

  { path: '/:pathMatch(.*)*', redirect: 'homepage' },
]

const deepThoughtRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'homepage',
    alias: '/login/twitter',
    component: DeepThoughtHomePage,
    meta: { layout: 'deep-thought' },
  },
  { path: '/premium', name: 'premium', component: DeepThoughtLoginPremiumPage, meta: { layout: 'deep-thought' } },
  { path: '/premium-suite', name: 'dashboard', component: DeepThoughtDashboardPage, meta: { layout: 'deep-thought' } },
  { path: '/talk-to-x', name: 'talktox', component: DeepThoughtTalkToXPage, meta: { layout: 'deep-thought' } },
  {
    path: '/premium-suite/followers',
    name: 'followers',
    component: DeepThoughtFollowersPage,
    meta: { layout: 'deep-thought' },
  },
  {
    path: '/premium-suite/following',
    name: 'following',
    component: DeepThoughtFollowingPage,
    meta: { layout: 'deep-thought' },
  },
  {
    path: '/premium-suite/post/:id',
    name: 'post',
    props: true,
    component: DeepThoughtPostPage,
    meta: { layout: 'deep-thought' },
  },

  { path: '/:pathMatch(.*)*', name: '404', component: DeepThoughtNotFoundPage, meta: { layout: 'deep-thought' } },
]

export const router = createRouter({
  history: createWebHistory(),
  routes:
    window.location.href.includes('xprotargeting.com') ||
    (window.location.href.includes('?protargeting=true') && window.location.href.includes('staging'))
      ? proTargetingRoutes
      : window.location.href.includes('deepthought.com') ||
          (window.location.href.includes('?deepthought=true') && window.location.href.includes('staging'))
        ? deepThoughtRoutes
        : dashboardRoutes,
  scrollBehavior(to, _, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth' })
        }, 200)
      })
    }

    if (savedPosition) {
      return savedPosition
    }

    return { top: 0 }
  },
})

router.onError((error) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') || // Chrome
    error.message.includes('error loading dynamically imported module') || // Firefox
    error.message.includes('Importing a module script failed') // Safari
  ) {
    const message = isInExtension.value
      ? "A new version of the app is available and a refresh is required to continue. Please refresh this page and try again. If that doesn't work, please close and reopen this page and try again."
      : 'A new version of the app is available and a refresh is required to continue. Please refresh this page and try again.'
    alert(message)
  }
})
