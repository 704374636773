import { ref } from 'vue'
import { Insights, MarketingMaterial } from '@/api'
import { LabeledValue } from '@/utils'

export const MAPPED_FRIENDLY_TITLES = {
  'pet-loving-community': 'Pet-loving Community',
  'euro-2024': 'UEFA Euro 2024',
  'us-politics': 'US Politics',
}

export const MAPPED_MATERIAL_TYPE_TO_FRIENDLY_NAME: Record<MarketingMaterial['document_type'], string> = {
  'one-pager': 'Product One-pagers',
  slides: 'Slide Deck',
  faq: 'FAQ',
  'case-study': 'Case Studies',
}

export const agencies = [
  'groupm',
  'test-agency',
  'omnicom',
  'havas',
  'dentsu-apac',
  'dentsu-nl',
  'ipg-apac',
  'wadi',
] as const

export type AgencyName = (typeof agencies)[number]

export const MAPPED_AGENCY_TO_TITLE: Record<AgencyName, string> = {
  groupm: 'GroupM',
  havas: 'Havas',
  omnicom: 'Omnicom Group',
  'test-agency': 'Test Agency',
  'dentsu-apac': 'Dentsu APAC',
  'dentsu-nl': 'Dentsu NL',
  'ipg-apac': 'IPG APAC',
  wadi: 'Wadi Digital',
}

export const MAPPED_AGENCY_TO_LOGO: Record<AgencyName, string> = {
  groupm: 'https://xprotargeting-assets.s3.amazonaws.com/GroupM_Logo.png',
  havas: 'https://xprotargeting-assets.s3.amazonaws.com/Havas_Logo.png',
  omnicom: 'https://xprotargeting-assets.s3.amazonaws.com/image.png',
  'test-agency': 'https://xprotargeting-assets.s3.amazonaws.com/Screenshot_from_2024-03-29_11-30-39.png',
  'dentsu-apac': 'https://xprotargeting-assets.s3.amazonaws.com/Dentsu_Logo_White_2.png',
  'dentsu-nl': 'https://xprotargeting-assets.s3.amazonaws.com/Dentsu_Logo_White_2.png',
  'ipg-apac': 'https://xprotargeting-assets.s3.amazonaws.com/Interpublic_Group_of_Companies_logo.png',
  wadi: 'https://xprotargeting-assets.s3.amazonaws.com/wadi-digital-logo.png',
}

type AgencyFilter = {
  countryIds?: number[]
  categoryIds?: number[]
}

export const MAPPED_AGENCY_TO_FILTERS: Partial<Record<AgencyName, AgencyFilter>> = {
  'dentsu-apac': {
    countryIds: [289],
  },
  'dentsu-nl': {
    countryIds: [151],
  },
  'ipg-apac': {
    countryIds: [289],
  },
}

export const MAPPED_COUNTRY_TO_X: Record<string, string> = {
  us: 'United States',
  usa: 'United States',
  'the united states': 'United States',
  'united states of america': 'United States',
  uae: 'United Arab Emirates',
  britain: 'United Kingdom',
  uk: 'United Kingdom',
  'the uk': 'United Kingdom',
  'the united kingdom': 'United Kingdom',
  'the netherlands': 'Netherlands',
  czechia: 'Czech Republic',
  türkiye: 'Turkey',
  turkiye: 'Turkey',
  'viet nam': 'Vietnam',
  // 'south korea': 'Republic of Korea', Note: in bulk upload it's 'south korea', in searchbar it's 'republic of korea'
}

export const PLACEMENTS = ['homeTimelines', 'profiles', 'searchResults', 'replies'] as const

export type Placement = (typeof PLACEMENTS)[number]

export const MAPPED_X_PLACEMENT_TO_FRIENDLY_NAME: Record<Placement, string> = {
  homeTimelines: 'Home Timelines',
  profiles: 'Profiles',
  searchResults: 'Search Results',
  replies: 'Replies',
}

export const ageRanges = [
  'AGE_ANY',
  'AGE_OVER_13',
  'AGE_OVER_18',
  'AGE_OVER_21',
  'AGE_OVER_25',
  'AGE_OVER_35',
  'AGE_OVER_50',
  'AGE_13_TO_24',
  'AGE_13_TO_34',
  'AGE_13_TO_49',
  'AGE_13_TO_54',
  'AGE_18_TO_24',
  'AGE_18_TO_34',
  'AGE_18_TO_49',
  'AGE_18_TO_54',
  'AGE_21_TO_34',
  'AGE_21_TO_49',
  'AGE_21_TO_54',
  'AGE_25_TO_49',
  'AGE_25_TO_54',
  'AGE_35_TO_49',
  'AGE_35_TO_54',
] as const

export type Age = (typeof ageRanges)[number]

export const MAPPED_X_AGE_TO_FRIENDLY_NAME: Record<Age, string> = {
  AGE_ANY: 'Any',
  AGE_OVER_13: 'Over 13',
  AGE_OVER_18: 'Over 18',
  AGE_OVER_21: 'Over 21',
  AGE_OVER_25: 'Over 25',
  AGE_OVER_35: 'Over 35',
  AGE_OVER_50: 'Over 50',
  AGE_13_TO_24: '13 to 24',
  AGE_13_TO_34: '13 to 34',
  AGE_13_TO_49: '13 to 49',
  AGE_13_TO_54: '13 to 54',
  AGE_18_TO_24: '18 to 24',
  AGE_18_TO_34: '18 to 34',
  AGE_18_TO_49: '18 to 49',
  AGE_18_TO_54: '18 to 54',
  AGE_21_TO_34: '21 to 34',
  AGE_21_TO_49: '21 to 49',
  AGE_21_TO_54: '21 to 54',
  AGE_25_TO_49: '25 to 49',
  AGE_25_TO_54: '25 to 54',
  AGE_35_TO_49: '35 to 49',
  AGE_35_TO_54: '35 to 54',
}

export const genders = ['ANY', 'MALE', 'FEMALE', 'Women', 'Men'] as const

export type Gender = (typeof genders)[number]

export const SOCIALDATABASE_AD_ACCOUNT_ID = 'ic6ic7'
export const JORAN_AD_ACCOUNT_ID = '18ce54xm10v'

export const GENDER_OPTIONS = ref<LabeledValue<Gender>[]>([
  { value: 'ANY', label: 'Any' },
  { value: 'FEMALE', label: 'Women' },
  { value: 'MALE', label: 'Men' },
])

export const AGE_OPTIONS = ref<LabeledValue<Age>[]>([
  { value: 'AGE_ANY', label: 'Any' },
  { value: 'AGE_OVER_13', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_13'] },
  { value: 'AGE_OVER_18', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_18'] },
  { value: 'AGE_OVER_21', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_21'] },
  { value: 'AGE_OVER_25', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_25'] },
  { value: 'AGE_OVER_35', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_35'] },
  { value: 'AGE_OVER_50', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_OVER_50'] },
  { value: 'AGE_13_TO_24', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_13_TO_24'] },
  { value: 'AGE_13_TO_34', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_13_TO_34'] },
  { value: 'AGE_13_TO_49', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_13_TO_49'] },
  { value: 'AGE_13_TO_54', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_13_TO_54'] },
  { value: 'AGE_18_TO_24', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_18_TO_24'] },
  { value: 'AGE_18_TO_34', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_18_TO_34'] },
  { value: 'AGE_18_TO_49', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_18_TO_49'] },
  { value: 'AGE_18_TO_54', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_18_TO_54'] },
  { value: 'AGE_21_TO_34', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_21_TO_34'] },
  { value: 'AGE_21_TO_49', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_21_TO_49'] },
  { value: 'AGE_21_TO_54', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_21_TO_54'] },
  { value: 'AGE_25_TO_49', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_25_TO_49'] },
  { value: 'AGE_25_TO_54', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_25_TO_54'] },
  { value: 'AGE_35_TO_49', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_35_TO_49'] },
  { value: 'AGE_35_TO_54', label: MAPPED_X_AGE_TO_FRIENDLY_NAME['AGE_35_TO_54'] },
])

export const AUDIENCE_SIZE_OPTIONS = ref([
  { label: '<1M', value: '<1M' },
  { label: '1M-5M', value: '1M-5M' },
  { label: '5M-12M', value: '5M-12M' },
  { label: '12M-25M', value: '12M-25M' },
  { label: '25M+', value: '25M+' },
  { label: 'I will let Socialdatabase decide', value: 'I will let Socialdatabase decide' },
])

export const CURRENCY_OPTIONS = ref([
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'YEN', value: 'YEN' },
  { label: 'SGD', value: 'SGD' },
  { label: 'BRL', value: 'BRL' },
  { label: 'CAD', value: 'CAD' },
  { label: 'CHF', value: 'CHF' },
  { label: 'INR', value: 'INR' },
  { label: 'KRW', value: 'KRW' },
  { label: 'KWD', value: 'KWD' },
  { label: 'MXN', value: 'MXN' },
  { label: 'NGN', value: 'NGN' },
  { label: 'SAR', value: 'SAR' },
  { label: 'AUD', value: 'AUD' },
  { label: 'ZAR', value: 'ZAR' },
  { label: 'Other', value: 'Other' },
])

export type Handle = NonNullable<Insights['targeting_package']>['handles'][number]

export const US_STATES = [
  'Region or state - Alabama, US',
  'Region or state - Alaska, US',
  'Region or state - Arizona, US',
  'Region or state - Arkansas, US',
  'Region or state - California, US',
  'Region or state - Colorado, US',
  'Region or state - Connecticut, US',
  'Region or state - Delaware, US',
  'Region or state - Florida, US',
  'Region or state - Georgia, US',
  'Region or state - Hawaii, US',
  'Region or state - Idaho, US',
  'Region or state - Illinois, US',
  'Region or state - Indiana, US',
  'Region or state - Iowa, US',
  'Region or state - Kansas, US',
  'Region or state - Kentucky, US',
  'Region or state - Louisiana, US',
  'Region or state - Maine, US',
  'Region or state - Maryland, US',
  'Region or state - Massachusetts, US',
  'Region or state - Michigan, US',
  'Region or state - Minnesota, US',
  'Region or state - Mississippi, US',
  'Region or state - Missouri, US',
  'Region or state - Montana, US',
  'Region or state - Nebraska, US',
  'Region or state - Nevada, US',
  'Region or state - New Hampshire, US',
  'Region or state - New Jersey, US',
  'Region or state - New Mexico, US',
  'Region or state - New York, US',
  'Region or state - North Carolina, US',
  'Region or state - North Dakota, US',
  'Region or state - Ohio, US',
  'Region or state - Oklahoma, US',
  'Region or state - Oregon, US',
  'Region or state - Pennsylvania, US',
  'Region or state - Rhode Island, US',
  'Region or state - South Carolina, US',
  'Region or state - South Dakota, US',
  'Region or state - Tennessee, US',
  'Region or state - Texas, US',
  'Region or state - Utah, US',
  'Region or state - Vermont, US',
  'Region or state - Virginia, US',
  'Region or state - Washington, US',
  'Region or state - Washington DC, US',
  'Region or state - West Virginia, US',
  'Region or state - Wisconsin, US',
  'Region or state - Wyoming, US',
]

export const UK_STATES =[
  'Region or state - England, GB',
  'Region or state - Scotland, GB',
  'Region or state - Wales, GB',
  'Region or state - Northern Ireland, GB',
]
